/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import hero1 from '../asset/img/hero1.webp';
import hero2 from '../asset/img/hero2.webp';
import hero3 from '../asset/img/hero3.webp';
import { MdArrowOutward } from "react-icons/md";
import axios from 'axios';
import { Helmet } from 'react-helmet';
const Home = () => {

    const [data, setdata] = useState({})

    useEffect(() => {

        async function getData() {
            let headersList = {
                "Accept": "*/*",
            }

            let reqOptions = {
                url: "https://api.webhizzy.in/user/64441f94ed57dc5cf68bc83c",
                method: "GET",
                headers: headersList,
            }

            let response = await axios.request(reqOptions);
            setdata(response.data);
        }
        getData();

    }, [])



    return (
        <>
        <Helmet>
            <title>{`${data?.first_name} ${data?.last_name} | Home`}</title>
        </Helmet>
            <div id="hero" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#hero" data-bs-slide-to="0" className="active" aria-current="true"
                        aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#hero" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#hero" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div className="carousel-inner">
                {data?.carousel?.map((carousel, index) => (
                    <div className={"carousel-item " + (index ? '' : 'active')}  key={index} >
                        <img src={carousel?.image} className="d-block w-100" alt="..." />
                        <div className="carousel-caption hero-caption">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-lg-6 text-center ">
                                    <h1>{carousel?.heading}</h1>
                                    <p>{carousel?.data}</p>
                                    <p className="mb-0"><a href="#" className="btn btn-primary px-4 py-2 rounded-pill">Get A Quote</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                </div>
            </div>
            <div className="section1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <h2 className="heading">We Create Building, and Interior Design That Enhance The Human Experience
                            </h2>
                            <p className="mb-5">{data?.introP}</p>
                            <p><a href="#" className="text-uppercase">More About Us <MdArrowOutward className="align-text-bottom fs-5" /></a></p>
                        </div>
                        <div className="col-md-4 ms-auto">
                            <div className="yearExperience">
                                <h2 className="heading">The Best In Interior Design</h2>
                                <span className="text">Years <span>of Experience</span></span>
                                <span className="number ms-4"><span>{data?.experience}</span></span>
                            </div>
                        </div>
                    </div>
                    <div id='Services'></div>
                </div>
            </div>
            <div className="site-section">
                <div className="container">
                    <div className="row  mb-5">
                        <div className="col-md-7">
                            <h2 className="heading">Our Featured <br /> Services</h2>
                            <p>{data?.featuredP}</p>
                            <div id='Projects'></div>
                            <p className="my-5"><a href="#" className="text-success">Learn More <MdArrowOutward className="align-text-bottom fs-5" /></a></p>
                        </div>
                    </div>
                    <div className="row">
                        <h2 className="heading mb-5">Projects</h2>
                        {data?.projects?.map((project, index) => (
                            <div className="col-md-6 aos-init " key={index} data-aos="fade-up" data-aos-delay="">
                                <div className="projectMedia">
                                    <img src={project?.image} alt="Image" className="img-fluid" />
                                    <div className="text">
                                        <span className="caption">{project?.category}</span>
                                        <h4><a href="#">{project?.name}</a></h4>
                                        <p className="my-5"><a href="#" className="text-success">Learn More <MdArrowOutward className="align-text-bottom fs-5" /></a></p>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        </>);
};

export default Home;