import { Outlet, Link } from "react-router-dom";
import logo from '../asset/img/logo.png';
import { MdFavorite} from "react-icons/md";
import { AiOutlineTwitter, AiOutlineInstagram} from "react-icons/ai";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { useEffect, useState } from "react";
import axios from "axios";
const Layout = () => {
    const [data, setdata] = useState({})

    useEffect(() => {

        async function getData() {
            let headersList = {
                "Accept": "*/*",
            }

            let reqOptions = {
                url: "https://api.webhizzy.in/user/64441f94ed57dc5cf68bc83c",
                method: "GET",
                headers: headersList,
            }

            let response = await axios.request(reqOptions);
            setdata(response.data);
        }
        getData();

    }, [])
  return (
    <>
    <nav className="navbar navbar-expand-lg navbar-light sticky-top shadow bg-white">
        <div className="container-fluid">
            <a className="navbar-brand d-md-none" href="#">
                <img src={logo} height="25px" alt=""/>
            </a>
            <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="fw-bolder navbar-nav mx-auto">
                    <li className="nav-item">
                        <Link to="/" className="nav-link" aria-current="page" href="#">Home</Link>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#Services">Services</a>
                    </li>
                    <a className="navbar-brand d-none d-md-block mx-5" href="#">
                        <img src={logo} height="25px" alt=""/>
                    </a>
                    <li className="nav-item">
                        <a className="nav-link" href="#Projects">Projects</a>
                    </li>
                    <li className="nav-item">
                        <Link to="/" className="nav-link">Contact</Link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

      <Outlet />
      <footer className="site-footer">
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-7">
                            <h2 className="footer-heading mb-4">About Us</h2>
                            <p>{data?.aboutUs}</p>
                        </div>
                        <div className="col-md-4 ml-auto">
                            <h2 className="footer-heading mb-4">Features</h2>
                            <ul className="list-unstyled">
                                <li><a href="#">About Us</a></li>
                                <li><a href="#">Testimonials</a></li>
                                <li><a href="#">Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 ms-auto">
                    <div className="mb-5">
                        <h2 className="footer-heading mb-4">Subscribe to Newsletter</h2>
                        <form action="#" method="post" className="footer-suscribe-form">
                            <div className="input-group mb-3">
                            <input type="text" className="form-control border-secondary text-white bg-transparent" placeholder="Enter Email" aria-label="Enter Email" />
                            <button className="btn btn-primary text-white" type="button" id="button-addon2">Subscribe</button>
                        </div>
                        </form>
                    </div>
                    <h2 className="footer-heading mb-4">Follow Us</h2>
                    <a href="#about-section" className="smoothscroll pl-0 pr-3"><FaFacebookF className="me-4 fs-5"/></a>
                    <a href="#" className="pl-3 pr-3"><AiOutlineTwitter className="me-4 fs-5"/></a>
                    <a href="#" className="pl-3 pr-3"><AiOutlineInstagram className="me-4 fs-5"/></a>
                    <a href="#" className="pl-3 pr-3"><FaLinkedinIn className="me-4 fs-5"/></a>

                </div>
            </div>
            <div className="row pt-5 mt-5 text-center">
                <div className="col-md-12">
                    <div className="py-5">

                            Copyright © <script>document.write(new Date().getFullYear());</script>
                            All rights reserved | Designed and developed with <MdFavorite/> by <a href="https://webhizzy.in" target="_blank">Webhizzy</a>

                        
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </>
  )
};

export default Layout;